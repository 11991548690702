import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';

const ProductsPage = ({ onProductSelect }) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/user/products');
                console.log('Response Data:', response.data);
        
                const productsArray = response.data.data || response.data;
                
                const productsData = productsArray.map(product => ({
                    ...product,
                    features: Array.isArray(product.features) ? product.features : JSON.parse(product.features)
                }));
                
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        

        fetchProducts();
    }, []);

    return (
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
            <h1 className="text-3xl font-bold mb-6">Pricing</h1>
            <h2 className="text-4xl font-bold mb-12 text-center">Save your time and money and generate everything!</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {products.map((product) => (
                    <div key={product.id} className={`p-6 bg-white shadow-md rounded-lg relative ${product.popular ? 'border-2 border-black' : ''}`}>
                        {product.popular && (
                            <div className="absolute top-0 right-0 bg-black text-white px-2 py-1 text-xs font-bold">POPULAR</div>
                        )}
                        <h2 className="text-2xl text-gray-700 font-semibold mb-2">{product.name}</h2>
                        <p className="text-gray-700 mb-4">{product.description}</p>
                        <div className="text-xl text-gray-700 mb-4">
                            <span className="font-bold">{product.currency} {product.price / 100}$</span>
                        </div>
                        <ul className="mb-4 text-gray-700">
                            {product.features.map((feature, index) => (
                                <li key={index} className="text-gray-700">✔ {feature}</li>
                            ))}
                        </ul>
                        <button
                            onClick={() => onProductSelect(product)}
                            className="w-full bg-black text-white-700 p-2 rounded-md hover:bg-gray-800"
                        >
                            Get The License!
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsPage;