import React, { useState, useEffect, useRef } from 'react';
import ProductsPage from './Products';
import PaymentComponent from './PaymentComponent';
import ProfileComponent from './ProfileComponent';
import { Disclosure } from '@headlessui/react';

function UserHome() {
    const [activeComponent, setActiveComponent] = useState('home');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const dropdownRef = useRef(null);

    const handleProductSelection = (product) => {
        setSelectedProduct(product);
        setActiveComponent('PaymentComponent');
    };

    const handleDisclosureToggle = (component) => {
        setActiveComponent(component);
    };

    return (
        <div className="bg-gray-900 w-full min-h-screen">
            <div className="mx-auto max-w-7xl w-full bg-gray-900">
                <Disclosure as="nav">
                    {({ open, close }) => (
                        <>
                            <div className="bg-gray-800 p-0 m-0 relative top-0 left-0 w-full">
                                <div className="flex items-center justify-between p-4">
                                    <Disclosure.Button className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none lg:hidden">
                                        Menu
                                    </Disclosure.Button>
                                    <div className="hidden lg:flex space-x-6">
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => { handleDisclosureToggle('home'); close(); }}>
                                            Home
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => { handleDisclosureToggle('ProfileComponent'); close(); }}>
                                            Profile
                                        </button>
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => { handleDisclosureToggle('ProductsPage'); close(); }}>
                                            Products
                                        </button>
                                       
                                        <button className="text-white hover:bg-gray-700 font-semibold w-full" onClick={() => { handleDisclosureToggle('PaymentComponent'); close(); }}>
                                            Payment Form
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                            <Disclosure.Panel className="lg:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2">
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => { handleDisclosureToggle('ProductsPage'); close(); }}>
                                        Products
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => { handleDisclosureToggle('PaymentComponent'); close(); }}>
                                        Payment Form
                                    </button>
                                    <button className="block w-full text-left px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 hover:bg-gray-700 focus:outline-none" onClick={() => { handleDisclosureToggle('ProfileComponent'); close(); }}>
                                        Profile
                                    </button>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {activeComponent === 'ProductsPage' && (
                    <ProductsPage onProductSelect={handleProductSelection} />
                )}

{activeComponent === 'home' && (
                    <div className=" bg-gray-900 py-10 w-full">
                        <div className="bg-gray-800 px-4 sm:px-6 lg:px-8 w-full">
                            <div className="bg-gray-800 sm:flex sm:items-center">
                                <div className=" bg-gray-800 sm:flex-auto">
                                <div>
                                <div class="bg-gray-800  text-white p-8">
  <div class="max-w-7xl mx-auto bg-gray-800">
    <h1 class="text-3xl font-bold mb-6 bg-gray-800 text-white">Welcome to Your Dashboard</h1>
    <p class="mb-8 text-lg bg-gray-800">
      This is your starting point after logging in. Here you can navigate through various sections of the site, each designed to make building, customizing, and managing your backend and frontend components more efficient and intuitive.
    </p>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 bg-gray-800">
     
      <div class="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-4">Laravel Generator</h2>
        <p class="mb-4">
          Quickly generate models, controllers, migrations, and routes for your Laravel projects. Customize each component to fit your needs.
        </p>
      </div>

     
      <div class="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-4">Node Component Generation</h2>
        <p class="mb-4">
          Generate models, controllers, and SQL scripts for Node.js applications with ease. Customize templates for greater flexibility.
        </p>
      </div>

     
      <div class="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-4">Python Component Generation</h2>
        <p class="mb-4">
          Easily generate Python scripts and backend components. Customize the templates to create Python code that fits your requirements.
        </p>
      </div>

   
      <div class="bg-gray-800 p-6 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-4">React Components</h2>
        <p class="mb-4">
          Create reusable React components for tables, forms, and login pages. Modify them to fit your frontend requirements.
        </p>
      </div>
    </div>

    <div class="mt-12">
      <p class="text-lg">
        Use the navigation menu to explore each section in more detail. Each area provides the tools you need to generate and customize backend and frontend components effectively.
      </p>
    </div>
  </div>
</div>

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeComponent === 'PaymentComponent' && selectedProduct && (
                    <PaymentComponent product={selectedProduct} />
                )}

                {activeComponent === 'ProfileComponent' && (
                    <ProfileComponent />
                )}
            </div>
        </div>
    );
}

export default UserHome;