import * as React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
/* import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'; */
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
//import axios from 'axios';
import axiosInstance from '../axios';

function Copyright(props) {
  
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
        SnapCode
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Custom styles using MUI's `styled` utility
const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[5],
}));

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const defaultTheme = createTheme();

const SignInUser = ({ onLoginSuccess , handleLoginSelection}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async () => {
   
    try {
      const response = await axiosInstance.post('/user/userLogin', {
        email,
        password: password,
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('admin', false);
        setLoggedIn(true);
        
        onLoginSuccess(); // Notify App component about successful login
      } else {
        setError(response.data.error.message);
        console.log(loggedIn,error)
      }
    } catch (error) {
      console.log('Error:', error);
      setError(error.response.data.error.message);
    }
  };


  return (
    <div className='adminlogin'>
    <ThemeProvider theme={defaultTheme}>
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <StyledBox>
          <StyledAvatar>
            <LockOutlinedIcon />
          </StyledAvatar>
          <Typography component="h1" variant="h5">
            User Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              sx={{
                backgroundColor: 'white', // Override any global background color
                borderRadius: 1,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: 'white', // Override any global background color
                borderRadius: 1,
              }}
            />
           
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                {/* <a onClick={() => handleLoginSelection('')}> {"Don't have an account? Sign Up"}</a> */}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </StyledBox>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </StyledContainer>
    </ThemeProvider>
    </div>
  );
}
export default SignInUser;