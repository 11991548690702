import React, { useEffect } from 'react';
import './NavBar.css';

const Navbar = ({ handleLoginSelection }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const navbar = document.querySelector('.navbar');
      const navbarLinks = document.querySelector('.navbar-links');
      if (navbarLinks.classList.contains('active') && !navbar.contains(event.target)) {
        navbarLinks.classList.remove('active');
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    const navbarLinks = document.querySelector('.navbar-links');
    navbarLinks.classList.toggle('active');
  };

  const handleLinkClick = (selection) => {
    handleLoginSelection(selection);
    const navbarLinks = document.querySelector('.navbar-links');
    navbarLinks.classList.remove('active');
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="#home" onClick={() => handleLinkClick('')}>SnappCode</a>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <ul className="navbar-links">
        {/* <li><a  onClick={() => handleLinkClick('register')}>Registration</a></li>*/}
        <li><a onClick={() => handleLinkClick('home')}>Home</a></li>
        <li><a  onClick={() => handleLinkClick('laravel')}>Laravel</a></li>
        <li><a  onClick={() => handleLinkClick('python')}>Python</a></li>
        <li><a onClick={() => handleLinkClick('affiliate')}>Affiliate Program</a></li>
        
        <li><a onClick={() => handleLinkClick('user')}>User login</a></li>
        <li><a onClick={() => handleLinkClick('admin')}>Admin login</a></li>
       
      </ul>
    </nav>
  );
};

export default Navbar;