import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableBuilder from './TableBuilder';
import axiosInstance from '../../axios';

const NodeMigrate = () => {
    const [tables, setTables] = useState([
        { id: 1, name: 'accounts', fields: [{ name: 'name', type: 'String', required: false, default: '' }], timestamps: true },
        { id: 2, name: 'users', fields: [{ name: 'role', type: 'String', required: true, default: '' }, { name: 'first_name', type: 'String', required: true, default: '' }, { name: 'last_name', type: 'String', required: true, default: '' }], timestamps: true }
    ]);
    const [notification, setNotification] = useState({ message: '', type: '' });

    const addTable = () => {
        setTables([...tables, { id: tables.length + 1, name: '', fields: [], timestamps: true }]);
    };

    const removeTable = (index) => {
        const updatedTables = tables.filter((_, i) => i !== index);
        setTables(updatedTables.map((table, index) => ({ ...table, id: index + 1 })));
    };

    const updateTable = (index, newTable) => {
        const updatedTables = [...tables];
        updatedTables[index] = newTable;
        setTables(updatedTables);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const updatedTables = Array.from(tables);
        const [reorderedTable] = updatedTables.splice(result.source.index, 1);
        updatedTables.splice(result.destination.index, 0, reorderedTable);

        setTables(updatedTables.map((table, index) => ({ ...table, id: index + 1 })));
    };

    const generateSQLScript = () => {
        let sqlScript = 'const SQL = `\n';

        tables.forEach(table => {
            sqlScript += `CREATE TABLE ${table.name} (\n`;
            table.fields.forEach(field => {
                let fieldSQL = `    ${field.name} ${field.type}`;
                if (field.required) {
                    fieldSQL += ' NOT NULL';
                } else {
                    fieldSQL += ' NULL';
                }
                if (field.default) {
                    fieldSQL += ` DEFAULT '${field.default}'`;
                }
                if (field.unique) {
                    fieldSQL += ' UNIQUE';
                }
                fieldSQL += ',\n';
                sqlScript += fieldSQL;
            });
            if (table.timestamps) {
                sqlScript += '    created_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP,\n';
                sqlScript += '    updated_at TIMESTAMP DEFAULT CURRENT_TIMESTAMP ON UPDATE CURRENT_TIMESTAMP,\n';
            }
            sqlScript = sqlScript.slice(0, -2); // Remove the last comma and newline
            sqlScript += '\n);\n\n';
        });
        sqlScript += '`;\n module.exports = SQL;'
        console.log(sqlScript);
        return sqlScript;
    };

    const sendToBackend = () => {
        deleteGenerations();
        const sqlScript = generateSQLScript();

        axiosInstance.post('/user/nodEmigration', { sqlScript })
            .then(response => {
                console.log('SQL script sent successfully:', response.data);
                setNotification({ message: 'SQL script sent successfully', type: 'success' });
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    console.error('There was an error!', error.response.data.message);
                    setNotification({ message: error.response.data.message, type: 'error' });
                } else {
                    console.error('Unexpected error!', error);
                    setNotification({ message: 'An unexpected error occurred.', type: 'error' });
                }
            });

        axiosInstance.post('/user/nodEgenerate', { tables })
            .then(response => {
                console.log('Tables sent successfully:', response.data.message);
                setNotification({ message: 'Tables sent successfully', type: 'success' });
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    console.error('There was an error!', error.response.data.message);
                    setNotification({ message: error.response.data.message, type: 'error' });
                } else {
                    console.error('Unexpected error!', error);
                    setNotification({ message: 'An unexpected error occurred.', type: 'error' });
                }
            });

        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    const generateZip = () => {
        axiosInstance.get('/user/nodeGenerateZip', {
            responseType: 'blob'
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'generated_files.zip');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setNotification({ message: 'Download started successfully', type: 'success' });
        })
        .catch(error => {
            if (error.response && error.response.data && error.response.data.message) {
                console.error('There was an error!', error.response.data.message);
                setNotification({ message: error.response.data.message, type: 'error' });
            } else {
                console.error('Unexpected error!', error);
                setNotification({ message: 'Nothing to Download', type: 'error' });
            }
        });

        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    const deleteGenerations = () => {
        axiosInstance.get('/user/nodeDeleteFiles')
            .then(response => {
                console.log('Files deleted successfully');
                setNotification({ message: 'Files deleted successfully', type: 'success' });
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    console.error('There was an error!', error.response.data.message);
                    setNotification({ message: error.response.data.message, type: 'error' });
                } else {
                    console.error('Unexpected error!', error);
                    setNotification({ message: 'An unexpected error occurred.', type: 'error' });
                }
            });

        setTimeout(() => {
            setNotification({ message: '', type: '' });
        }, 3000);
    };

    return (
        <div className='container-migration bg-gray-900 p-6 rounded-lg shadow-lg max-w-4xl mx-auto'>
            
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="tables">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-4'>
                            {tables.map((table, index) => (
                                <Draggable key={index} draggableId={String(index)} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <button className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600' onClick={addTable}>Add New Table</button>
                                            <TableBuilder
                                                index={index}
                                                table={table}
                                                updateTable={updateTable}
                                                removeTable={removeTable}
                                                tables={tables}
                                            />
                

                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <button className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600' onClick={addTable}>Add New Table</button>
            {notification.message && (
                <div
                    className={`mb-4 p-4 rounded-md ${
                        notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                    } text-white`}
                >
                    {notification.message}
                </div>
            )}
            
            <div className='flex flex-wrap space-x-2 mt-8'>
                <button className='bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600' onClick={sendToBackend}>Generate Code</button>
                <button className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600' onClick={generateZip}>Download Code</button>
            </div>
            <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    );
};

export default NodeMigrate;