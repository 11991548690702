import React, { useEffect } from 'react';
import './ProductLandingPage.css';
import Typed from 'react-typed';

const ProductLandingPage = ({ handleLoginSelection }) => {
    
      const handleLinkClick = (selection) => {
        handleLoginSelection(selection);
        const navbarLinks = document.querySelector('.linkki');
        navbarLinks.classList.remove('active');
      };

  return (
    <div className="landing-page2">
      <div className="left-section">
      <h2 className="left-headline">Hot Right Now: AI!</h2> <br></br>
        <div className="landing-image"></div>
        
        <h2 className="left-headline2">Take the boring stuff out from the scale and use your time for coding more effectively. With SnappCode you 
            will have time to generate better AI pictures than we did.
        </h2>
        <button
          type="submit"
          className="linkki bg-green-600 hover:bg-green-500 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
          onClick={() => handleLinkClick('user')}
        >
          Join SnappCode!
        </button>
      </div>
      <div className="right-section">
        <h1 className="hyppyteksti main-headline">Let SnappCode do {' '} <Typed
          strings={['the repeatitive work.', 'Databases and Routes.', ' Controllers and Models.']}
          typeSpeed={40}
          backSpeed={50}
          loop
        /></h1>
        <p className="code-snippet">
          {'// I want to use my time better.\n'}
          {'function SnappCode() {\n'}
          {'  return($Time, $Value, $Fun, $Innovation);\n'}
          {'}'}
        </p>
        <button
          type="submit"
          className="linkki bg-blue-900 hover:bg-blue-700 text-white font-bold py-4 px-10 mt-4 rounded-lg shadow-lg transition duration-300"
           onClick={() => handleLinkClick('laravel')}
        >
          Read more  
        </button>
      </div>
    </div>
  );
};

export default ProductLandingPage;