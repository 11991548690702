import React, { useState, useEffect } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axiosInstance from '../axios';
import visaLogo from '../Visa_Brandmark_Blue_RGB_2021.png';
import mastercardLogo from '../MasterCard_Logo.svg.png';

const PaymentForm = ({ product }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [succeeded2, setSucceeded2] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [fail, setFail] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');

    const [email, setEmail] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [country] = useState('FI'); // Default to Finland for this example
    const [couponCode, setCouponCode] = useState('');
    const [discountedPrice, setDiscountedPrice] = useState(product.price);
    const [newPrice, setNewPrice] = useState(null);

    useEffect(() => {
        const createPaymentIntent = async () => {
            try {
                const { data } = await axiosInstance.post('/create-payment-intent', { amount: discountedPrice });
                setClientSecret(data.clientSecret);
            } catch (error) {
                console.error("Error creating payment intent:", error);
            }
        };
        createPaymentIntent();
        console.log(product, 'produkti')
    }, [discountedPrice, product]);

    const handleChange = async (event) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };

    const handleCouponSubmit = async () => {
        try {
            console.log(product.price);
            const { data } = await axiosInstance.post('/apply-coupon', { code: couponCode, price: product.price });
            if (data.success) {
                setDiscountedPrice(data.newPrice);
                setNewPrice(data.newPrice);
                console.log(data.newPrice);
                setSucceeded2(true);
            } else {
                setFail(true);
            }
        } catch (error) {
            setError("Error applying coupon code");
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    email: email,
                    name: cardHolderName,
                    address: {
                        country: country,
                    },
                },
            },
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            try {
                const finalPrice = newPrice !== null ? newPrice : product.price;
                await axiosInstance.post('/create-user', {
                    email: email,
                    name: cardHolderName,
                    role: product.role,
                    code: couponCode,
                    price: finalPrice,
                    product_id: product.id,
                });
                console.log(finalPrice, product.id);
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                // Redirect to the user login page or root after successful payment and user creation
                window.location.href = '/#Frontpage'; // Redirect to the frontpage
                // Alternatively, you can redirect to the user login page:
                // window.location.href = '/#user-login';
            } catch (error) {
                setError("Payment succeeded, but failed to create user");
                setProcessing(false);
            }
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg flex">
            <div className="w-1/2 pr-4">
                <div className="mb-4">
                    <h2 className="text-lg font-semibold mb-2 text-gray-900">{product.name}</h2>
                    <p className="text-gray-700">{product.description}</p>
                    <p className="text-2xl font-bold mt-4 text-gray-900">{discountedPrice / 100} $</p>
                </div>
                <div className="mb-4">
                    <label htmlFor="coupon-code" className="block text-sm font-medium text-gray-700">Enter coupon code</label>
                    <input
                        id="coupon-code"
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                    />
                </div>
                <button onClick={handleCouponSubmit} className="text-lg font-semibold mb-2 text-gray-900">Submit</button>
                {succeeded2 && <p className="result-message mt-2 text-green-500">Coupon code applied</p>}
                {fail && <p className="result-message mt-2 text-red-500">Coupon code not valid!</p>}
            </div>
            <div className="w-1/2 pl-4">
                <h2 className="block text-gray-900">Pay with card</h2>
                <br />
                <form id="payment-form" onSubmit={handleSubmit} className="space-y-4">
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="card-holder-name" className="block text-sm font-medium text-gray-700">Card Holder's Name</label>
                        <input
                            id="card-holder-name"
                            type="text"
                            value={cardHolderName}
                            onChange={(e) => setCardHolderName(e.target.value)}
                            required
                            className="mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                        />
                    </div>
                    <div className="relative mb-4">
                        <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">Card Details</label>
                        <div className="flex items-center border border-gray-300 rounded-md p-2">
                            <CardNumberElement
                                id="card-number"
                                onChange={handleChange}
                                className="flex-grow focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <img src={visaLogo} alt="Visa" className="ml-2 h-6" />
                            <img src={mastercardLogo} alt="Mastercard" className="ml-2 h-6" />
                        </div>
                    </div>
                    <div className="flex space-x-4 mb-4">
                        <div className="w-1/2">
                            <div className="relative">
                                <CardExpiryElement
                                    id="card-expiry"
                                    onChange={handleChange}
                                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                    placeholder="MM/YY"
                                />
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="relative">
                                <CardCvcElement
                                    id="card-cvc"
                                    onChange={handleChange}
                                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                                    placeholder="CVC"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center mb-4">
                        <input
                            id="save-info"
                            type="checkbox"
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="save-info" className="ml-2 block text-sm text-gray-900">
                            Save my information for future purchases
                        </label>
                    </div>
                    <button
                        disabled={processing || disabled || succeeded}
                        id="submit"
                        className={`w-full bg-blue-500 text-white p-2 rounded-md ${processing || disabled || succeeded ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
                    >
                        <span id="button-text">
                            {processing ? <div className="spinner" id="spinner"></div> : 'Pay'}
                        </span>
                    </button>
                    {error && <div className="card-error mt-2 text-red-500" role="alert">{error}</div>}
                    {succeeded && <p className="result-message mt-2 text-green-500">Payment succeeded. Password sent to your email</p>}
                </form>
            </div>
        </div>
    );
};

export default PaymentForm;