import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import axiosInstance from '../axios';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="">
      SnapCode
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// Custom styles using MUI's `styled` utility
const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
  }));
  
  const StyledBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));
  
  const StyledAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }));

//const defaultTheme = createTheme();
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#ff3752', // Your custom primary color
    },
    secondary: {
      main: '#798f50', // Your custom secondary color
    },
    background: {
      default: '#ffffff', // Custom background color
    },
    text: {
      primary: '#000000', // Custom text color
    },
  },
  typography: {
    fontFamily: ' sans-serif', // Custom font
  },
  shape: {
    borderRadius: 8, // Custom border radius for components
  },
});

const SignUp = ({ handleLoginSelection }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(`${name}: ${value}`); // Debugging line to see each input value
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Optional: Simple client-side validation
    if (!formData.name || !formData.email || !formData.password) {
      alert('All fields are required.');
      return;
    }

    try {
      console.log('Form data being submitted:', formData); // Debugging line before submitting
      const response = await axiosInstance.post('registerOnline', formData);
      console.log('Form submitted successfully:', response.data);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className='adminlogin'>
    <ThemeProvider theme={customTheme}>
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <StyledBox
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <StyledAvatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <EditNoteRoundedIcon/>
          </StyledAvatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href="#" variant="body1" >
                 <a href='' onClick={() => handleLoginSelection('user')}> Already have an account? Sign in</a>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </StyledBox>
        <Copyright sx={{ mt: 5 }} />
      </StyledContainer>
    </ThemeProvider>
    </div>
  );
}
export default SignUp;