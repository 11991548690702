import React, { useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import LaravelMainComponent from '../Laravel/laravelMainComponent';
import NodeMainComponent from '../Node/nodeMainComponent';
import PythonMainComponent from '../Python/pythonMainComponent';
import ReactMainComponent from '../React/reactMainComponent';
import UserHome from '../Home/userHome';
import ProfileComponent from '../Home/ProfileComponent';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function UserDashboard({ setBgClass }) {
  const [activeView, setActiveView] = useState('userHome');
  const [showProfile, setShowProfile] = useState(false);

  const handleViewToggle = (view, close) => {
    setActiveView(view);
    close();
    switch (view) {
      case 'LaravelMainComponent':
        setBgClass('bg-laravel');
        break;
      case 'NodeMainComponent':
        setBgClass('bg-node');
        break;
      case 'PythonMainComponent':
        setBgClass('bg-python');
        break;
      case 'ReactMainComponent':
        setBgClass('bg-react');
        break;
      case 'userHome':
      default:
        setBgClass('bg-home');
        break;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loginType'); // Clear the login type
    localStorage.removeItem('admin');
    window.location.reload();
  };

  const openProfile = () => {
    setShowProfile(true);
  };

  const closeProfile = () => {
    setShowProfile(false);
  };

  return (
    <div className="min-h-full relative">
      <Disclosure as="nav">
        {({ open, close }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="navBar flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <Disclosure.Button className="inline-flex items-center rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none lg:hidden">
                    Menu
                  </Disclosure.Button>
                  <div className="hidden lg:flex space-x-4">
                    <button
                      className={classNames(
                        'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
                        activeView === 'userHome' ? 'bg-teal-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                      )}
                      onClick={() => handleViewToggle('userHome', close)}
                    >
                      Home
                    </button>
                    <button
                      className={classNames(
                        'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
                        activeView === 'LaravelMainComponent' ? 'bg-red-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                      )}
                      onClick={() => handleViewToggle('LaravelMainComponent', close)}
                    >
                      Laravel
                    </button>
                    <button
                      className={classNames(
                        'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
                        activeView === 'NodeMainComponent' ? 'bg-green-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                      )}
                      onClick={() => handleViewToggle('NodeMainComponent', close)}
                    >
                      Node
                    </button>
                    <button
                      className={classNames(
                        'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
                        activeView === 'PythonMainComponent' ? 'bg-blue-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                      )}
                      onClick={() => handleViewToggle('PythonMainComponent', close)}
                    >
                      Python
                    </button>
                    <button
                      className={classNames(
                        'inline-flex items-center rounded-md px-3 py-2 text-sm font-medium focus:outline-none',
                        activeView === 'ReactMainComponent' ? 'bg-yellow-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                      )}
                      onClick={() => handleViewToggle('ReactMainComponent', close)}
                    >
                      React
                    </button>
                  </div>
                </div>
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://picsum.photos/200/400"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              openProfile();
                              close();
                            }}
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={handleLogout}
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <button
                  className={classNames(
                    'block w-full text-left px-3 py-2 rounded-md text-sm font-medium focus:outline-none',
                    activeView === 'userHome' ? 'bg-teal-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                  )}
                  onClick={() => handleViewToggle('userHome', close)}
                >
                  Home
                </button>
                <button
                  className={classNames(
                    'block w-full text-left px-3 py-2 rounded-md text-sm font-medium focus:outline-none',
                    activeView === 'LaravelMainComponent' ? 'bg-red-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                  )}
                  onClick={() => handleViewToggle('LaravelMainComponent', close)}
                >
                  Laravel
                </button>
                <button
                  className={classNames(
                    'block w-full text-left px-3 py-2 rounded-md text-sm font-medium focus:outline-none',
                    activeView === 'NodeMainComponent' ? 'bg-green-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                  )}
                  onClick={() => handleViewToggle('NodeMainComponent', close)}
                >
                  Node
                </button>
                <button
                  className={classNames(
                    'block w-full text-left px-3 py-2 rounded-md text-sm font-medium focus:outline-none',
                    activeView === 'PythonMainComponent' ? 'bg-blue-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                  )}
                  onClick={() => handleViewToggle('PythonMainComponent', close)}
                >
                  Python
                </button>
                <button
                  className={classNames(
                    'block w-full text-left px-3 py-2 rounded-md text-sm font-medium focus:outline-none',
                    activeView === 'ReactMainComponent' ? 'bg-yellow-500 text-white' : 'bg-gray-900 text-white hover:bg-gray-700'
                  )}
                  onClick={() => handleViewToggle('ReactMainComponent', close)}
                >
                  React
                </button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className="flex-grow">
        <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row md:flex-nowrap">
            {activeView === 'LaravelMainComponent' && (
              <div className="w-full md:w-auto flex-grow">
                <LaravelMainComponent />
              </div>
            )}
            {activeView === 'NodeMainComponent' && (
              <div className="w-full md:w-auto flex-grow">
                <NodeMainComponent />
              </div>
            )}
            {activeView === 'PythonMainComponent' && (
              <div className="w-full md:w-auto flex-grow">
                <PythonMainComponent />
              </div>
            )}
            {activeView === 'ReactMainComponent' && (
              <div className="w-full md:w-auto flex-grow">
                <ReactMainComponent />
              </div>
            )}
            {activeView === 'userHome' && (
              <div className="w-full md:w-auto flex-grow">
                <UserHome />
              </div>
            )}
          </div>
        </div>
      </main>

      {showProfile && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
          <div className="p-8 rounded-lg shadow-lg w-full max-w-3xl relative">
            <button
              className="absolute top-2 right-2 text-white "
              onClick={closeProfile}
            >
              Close
            </button>
            <ProfileComponent />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDashboard;
