import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axios';

const ProfileComponent = () => {
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    affiliate_coupon: '',
    coupon_amount: '',
    password: '',
    password_confirmation: '',
  });
  const [notification, setNotification] = useState({ message: '', type: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('user/me');
        const result = response.data;

        if (result.data) {
          setData(result.data);
          setFormData({
            name: result.data.name,
            email: result.data.email,
            affiliate_coupon: result.data.affiliate_coupon || '',
            coupon_amount: result.data.coupon_amount || '',
            password: '',
            password_confirmation: '',
          });
        } else {
          console.error('Expected an object but got:', result.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put('user/me', formData);
      setNotification({ message: 'Profile updated successfully!', type: 'success' });
      setData(response.data.data);
    } catch (error) {
      setNotification({ message: 'Error updating profile. Please try again.', type: 'error' });
      console.log('Error updating profile:', error);
    }

    // Clear the notification after a few seconds
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 3000);
  };

  return (
    <div className=" w-full p-4 sm:p-6">
      <div className="bg-gray-900 p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-5xl mx-auto">
        <h1 className="text-xl sm:text-2xl font-bold text-white mb-4 text-left">User Profile</h1>
        {notification.message && (
          <div
            className={`mb-4 p-4 rounded-md ${
              notification.type === 'success' ? 'bg-green-500' : 'bg-red-500'
            } text-white`}
          >
            {notification.message}
          </div>
        )}
        {data ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label className="block text-white mb-2">Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">Affiliate Coupon Name:</label>
              <input
                type="text"
                name="affiliate_coupon"
                value={formData.affiliate_coupon}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">Affiliate Coupon Earnings $:</label>
              <input
                type="text"
                name="coupon_amount"
                value={formData.coupon_amount}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">New Password:</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <div className="mb-4">
              <label className="block text-white mb-2">Confirm New Password:</label>
              <input
                type="password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                className="w-full max-w-full sm:max-w-xs p-2 rounded-md bg-gray-700 text-black border border-gray-600"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 px-4 py-2 rounded-md text-white font-semibold hover:bg-blue-600 w-full sm:w-auto"
            >
              Update Profile
            </button>
          </form>
        ) : (
          <p className="text-white">Loading...</p>
        )}
      </div>
    </div>
  );
};

export default ProfileComponent;
